import anime, { stagger } from 'animejs';
import LocomotiveScroll from 'locomotive-scroll';

let scroll;

/* ------------------------------------ */
/* ------ Locomotive scroll init ------ */
/* ------------------------------------ */
const createScroll = (container) => {
    let elt = container.querySelector('[data-scroll-container]');

    scroll = new LocomotiveScroll({
        el: elt,
        smooth: true,
        getSpeed: true,
        getDirection: true,
        gestureDirection: 'both',
        tablet: {
            smooth: true
        },
        smartphone: {
            smooth: false
        }
    });

    return scroll;
};

const page = () => {


    document.querySelectorAll('.project').forEach((project) => {
        let marquee = document.querySelector('.marquee[data-project="' + project.getAttribute('data-project') + '"');
        console.log(project);
        project.addEventListener('mouseenter', () => {
            marquee.classList.add('visible');
        });
        project.addEventListener('mouseleave', () => {
            marquee.classList.remove('visible');
        });
    })
    

};


(function() {
    
    scroll = createScroll(document);

    page();

})();